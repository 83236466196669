<template>
    <div id="replacementPerformanceComparison" style="width: 100%; height: 500px;"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import axios from "axios";

export default {
    name: "ComparisonChart",
    data() {
        return {
            chart: null,
        };
    },
    methods: {
        async fetchData() {
            const apiUrl = "/api/distribution/replacement/comparison?first_range=Jan-Mar 2024&second_range=Jan-Mar 2023";
            try {
                const response = await axios.get(apiUrl);
                const rawData = response.data?.data;
                if (rawData) {
                    return this.transformData(rawData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                return [];
            }
        },
        transformData(rawData) {
            const chartData = [];
            for (const month in rawData) {
                rawData[month].forEach((entry) => {
                    chartData.push({
                        month,
                        label: entry.label,
                        value: entry.value,
                    });
                });
            }
            return chartData;
        },
        async createChart() {
            const chartData = await this.fetchData();

            const chart = am4core.create("replacementPerformanceComparison", am4charts.XYChart);
            chart.data = chartData;

            const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "label";
            categoryAxis.title.text = "Months";

            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Values";

            const series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "value";
            series.dataFields.categoryX = "label";
            series.name = "Value";
            series.columns.template.tooltipText = "{label}: [bold]{valueY}[/]";
            series.columns.template.fillOpacity = 0.8;

            this.chart = chart;
        },
    },
    mounted() {
        this.createChart();
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
};
</script>

<style scoped>
/* Optional: Customize chart styling */
</style>