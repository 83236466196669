<template>
  <div>
    <div v-if="isLoading" class="loading text-center h-100 d-flex align-items-center justify-content-center">
      <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
    </div>
    <div v-else>
      <div class="row mt-2 mb-3">
        <div class="col-md-6 col-12">
          <h4 class="mb-0">QR Registration</h4>
        </div>
        <div class="col-md-2 offset-md-4 d-flex toggle-switch-container justify-content-end">
          <h6 class="mr-2">Show Performance Year</h6>
          <label class="switch">
            <toggle-button :width="50" :height="30" color="#134F97" v-model="showPerformanceYear" @change="onToggleChange" />
          </label>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-3" style="padding-right: 0">
              <button
                style="border-radius: 6px 0px 0px 6px"
                type="button"
                class="w-100 btn"
                :class="{
                  'btn-outline-primary': registrationType != 'New ID',
                  'btn-primary': registrationType == 'New ID',
                }"
                @click="switchRegistrationType('New ID')"
              >
                New ID
              </button>
            </div>
            <div class="col-md-3" style="padding-left: 0">
              <button
                style="border-radius: 0px 6px 6px 0px"
                type="button"
                class="w-100 btn"
                :class="{
                  'btn-outline-primary': registrationType != 'Existing ID',
                  'btn-primary': registrationType == 'Existing ID',
                }"
                @click="switchRegistrationType('Existing ID')"
              >
                Existing
              </button>
            </div>
            <div class="col-12 col-md-3 mt-2 mt-md-0">
              <div class="filter-date icon fa-calendar">
                <flat-pickr v-model="dateRange.date" :config="rangeConfig" class="w-100" placeholder="Select Date" />
              </div>
            </div>
            <div class="col-12 col-md-3 mt-2 mt-md-0">
              <Multiselect v-model="selectedYearGlobal" :options="optionYears" placeholder="All Years" @input="selectGlobalYear" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4" v-if="registrationType === 'New ID'">
        <div class="col-md-8">
          <div
            class="card d-flex flex-column relative"
            :class="{
              '': registrationType != 'New ID',
              'new-id-production-year': registrationType == 'New ID',
            }"
          >
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <h5 class="card-title mb-0 mr-2">{{ registrationType }}</h5>
                <div class="tooltip-container">
                  <span><i class="fas fa-info-circle"></i></span>
                  <div class="tooltip">
                    <div class="tooltip-content">Kendaraan yang pertama kali melakukan servis di H2</div>
                  </div>
                </div>
                <div class="d-flex ml-auto">
                  <Multiselect v-model="selectedChartStacked" class="mt-1" :options="optionChartStacked" track-by="loc_code" label="loc_code" placeholder="All Dealers" style="width: 150px"></Multiselect>
                  <button class="btn btn-outline-secondary ml-2 mt-1" @click="downloadExcelChartStacked()" style="width: 150px; height: 40px">
                    Download CSV
                    <i class="bi bi-download"></i>
                  </button>
                </div>
              </div>
              <div v-if="isLoadingChartStacked" class="loading text-center h-100 d-flex align-items-center justify-content-center">
                <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
              </div>
              <new-id-chart-stacked-column
                v-else="dataChartStacked"
                :start-date="dateRange.start"
                :end-date="dateRange.end"
                :selected-year-global="selectedYearGlobal"
                :selected-chart-dealer="selectedChartStacked"
                :chart-data="dataChartStacked"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4" v-if="registrationType === 'New ID'">
          <div
            class="card d-flex flex-column relative"
            :class="{
              '': registrationType != 'New ID',
              'new-id-production-year': registrationType == 'New ID',
            }"
          >
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <h6 class="card-title mb-0 mr-2">
                  Overall by Production Year
                  <div class="tooltip-container">
                    <span><i class="fas fa-info-circle"></i></span>
                    <div class="tooltip">
                      <div class="tooltip-content">Presentase data new ID berdasarkan tahun produksi</div>
                    </div>
                  </div>
                </h6>
                <div class="d-flex ml-auto">
                  <button class="btn btn-outline-secondary ml-1 mt-1" @click="downloadExcelOverallProductionYear()" style="width: 150px; height: 40px">
                    Download CSV
                    <i class="bi bi-download"></i>
                  </button>
                </div>
              </div>
              <div v-if="isLoadingOverallProductionYear" class="loading text-center h-100 d-flex align-items-center justify-content-center">
                <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
              </div>
              <new-id-chart-overall-production-year v-else="dataChartOverallProductionYear" :start-date="dateRange.start" :end-date="dateRange.end" :selected-year-global="selectedYearGlobal" :chart-data="dataChartOverallProductionYear" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="registrationType === 'New ID' && showPerformanceYear" class="card mb-4 relative">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <h5 class="card-title mb-0 mr-2">Perfomance Comparison</h5>
            <div class="tooltip-container">
              <span><i class="fas fa-info-circle"></i></span>
              <div class="tooltip">
                <div class="tooltip-content">Perbandingan antar tahun data kendaraan yang melakukan pergantian QR di H2</div>
              </div>
            </div>
            <div class="d-flex ml-auto">
              <div class="filter-date icon fa-calendar mr-3">
                <flat-pickr v-model="dateRange.date" :config="rangeConfig" class="mt-0 mb-0" placeholder="Select Date" />
              </div>

              <h5 class="mr-3">and</h5>

              <div class="filter-date icon fa-calendar mr-3">
                <flat-pickr v-model="dateRange.date" :config="rangeConfig" class="mt-0 mb-0" placeholder="Select Date" />
              </div>
              <Multiselect class="mt-1 ml-2" v-model="selectedSegment" :options="optionSegments" :multiple="true" placeholder="region" style="width: 200px"></Multiselect>
              <button class="btn btn-outline-secondary ml-2" @click="downloadExcelSegment()" style="width: 200px; height: 40px; margin-top: 4px">
                Download CSV
                <i class="bi bi-download"></i>
              </button>
            </div>
          </div>
          <div v-if="isLoadingChartStacked" class="loading text-center h-100 d-flex align-items-center justify-content-center">
            <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
          </div>
          <new-id-chart-perfomance-comparison v-if="showPerformanceYear" :start-date="dateRange.start" :end-date="dateRange.end" />
        </div>
      </div>

      <div class="card mb-4 relative" v-if="registrationType === 'New ID'">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <h5 class="card-title mb-0 mr-2">Segment</h5>
            <div class="tooltip-container">
              <span><i class="fas fa-info-circle"></i></span>
              <div class="tooltip">
                <div class="tooltip-title">Segment</div>
                <div class="tooltip-content">Kendaraan yang pertama kali melakukan servis di H2 dilihat berdasarkan segmentasi motor</div>
              </div>
            </div>
            <div class="d-flex ml-auto">
              <Multiselect class="mt-1 ml-2" v-model="selectedSegment" :options="optionSegments" :multiple="true" placeholder="Select Segments" style="width: 200px"></Multiselect>
              <Multiselect class="mt-1 ml-2" v-model="selectedYearSegment" :options="optionYears" placeholder="All Years" style="width: 200px"></Multiselect>
              <button class="btn btn-outline-secondary ml-2" @click="downloadExcelSegment()" style="width: 200px; height: 40px; margin-top: 4px">
                Download CSV
                <i class="bi bi-download"></i>
              </button>
            </div>
          </div>
          <div v-if="isLoadingSegment" class="loading text-center h-100 d-flex align-items-center justify-content-center">
            <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
          </div>
          <new-id-chart-segment
            v-else="dataSegment"
            :start-date="dateRange.start"
            :end-date="dateRange.end"
            :selected-year="selectedYearGlobal"
            :selected-year-segment="selectedYearSegment"
            :selected-segments="selectedSegment"
            :chart-data="dataSegment"
          />
        </div>
      </div>

      <div class="card mb-4 relative card-table" v-if="registrationType === 'New ID'">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <h5 class="card-title mb-0 mr-2">{{ registrationType }} by Region</h5>
            <div class="tooltip-container">
              <span><i class="fas fa-info-circle"></i></span>
              <div class="tooltip">
                <div class="tooltip-content">Kendaraan yang pertama kali melakukan servis di H2 berdasarkan lokasi region</div>
              </div>
            </div>
            <div class="d-flex ml-auto">
              <Multiselect v-model="selectedRegion" :options="optionRegions" placeholder="Semua MD" class="mt-1 ml-2" style="width: 200px"></Multiselect>
              <Multiselect v-model="selectedYearRegion" :options="optionYears" placeholder="All Years" class="mt-1 ml-2" style="width: 200px"></Multiselect>

              <button class="btn btn-outline-secondary ml-2 align-self-end" @click="downloadExcelRegion()" style="width: 150px; height: 40px">
                Download CSV
                <i class="bi bi-download"></i>
              </button>
            </div>
          </div>
          <div v-if="isLoadingRegion" class="loading text-center h-100 d-flex align-items-center justify-content-center">
            <img src="/img/loading.gif" width="48" height="48" :alt="`Loading ${registrationType} Region`" />
          </div>
          <div v-else>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="hso-table-header" style="background-color: #ffffff">Region Name</th>
                  <th v-for="month in displayedMonths" :key="month" class="hso-table-header" style="background-color: #ffffff">
                    {{ month }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="dataRegion.length === 0">
                  <td colspan="100%" style="padding: 2rem 0">
                    <p style="font-weight: bold; font-size: 1.5rem; line-height: 1">No Data Available.</p>
                    <p style="margin-top: -0.5rem">Please choose another time or option</p>
                  </td>
                </tr>
                <tr v-else v-for="(regionData, regionName) in paginatedData" :key="regionName">
                  <td class="hso-table-body" style="border-bottom: none; border-top: none">{{ regionName }}</td>
                  <td v-for="month in displayedMonths" :key="month" class="hso-table-body" style="border-bottom: none; border-top: none">
                    {{ getValueForMonth(regionData, month) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- New ID Region Pagination -->
            <div class="d-flex justify-content-between align-items-center mt-3">
              <div>Showing {{ paginationStart + 1 }} to {{ paginationEnd }} of {{ totalItems }} entries</div>
              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="rowsPerPage" aria-controls="replacement-table"></b-pagination>
            </div>
          </div>
        </div>
      </div>

      <b-card class="mb-4 relative card-table" v-if="registrationType === 'New ID'">
        <div class="d-flex align-items-center mb-3">
          <h5 class="card-title mb-0 mr-2">{{ registrationType }} by Dealer</h5>
          <div class="tooltip-container">
            <span><i class="fas fa-info-circle"></i></span>
            <div class="tooltip">
              <div class="tooltip-content">Kendaraan yang pertama kali melakukan servis di H2 berdasarkan lokasi dealer</div>
            </div>
          </div>
          <div class="d-flex ml-auto">
            <Multiselect v-model="selectedDealer" class="mt-1 ml-2" :options="optionDealers" placeholder="All Dealer" style="width: 200px"></Multiselect>
            <Multiselect v-model="selectedYearDealer" class="mt-1 ml-2" :options="optionYears" placeholder="All Years" style="width: 200px" @change="selectedYearDealer"></Multiselect>
            <button class="region-download btn btn-outline-secondary ml-2" @click="downloadExcelDealer()" style="width: 150px; height: 40px; margin-top: 4px">
              Download CSV
              <i class="bi bi-download"></i>
            </button>
          </div>
        </div>
        <div v-if="isLoadingDealer" class="loading text-center h-100 d-flex align-items-center justify-content-center">
          <img src="/img/loading.gif" width="48" height="48" :alt="`Loading ${registrationType} Dealer`" />
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th class="hso-table-header" style="background-color: #ffffff">Dealer Name</th>
                <th v-for="(year, index) in optionYears.slice(1)" :key="index" v-if="!selectedYearDealer || selectedYearDealer === 'All Years' || selectedYearDealer === year" class="hso-table-header" style="background-color: #ffffff">
                  {{ year }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="dataDealer.length === 0">
                <td colspan="100%" style="padding: 2rem 0">
                  <p style="font-weight: bold; font-size: 1.5rem; line-height: 1">No Data Available.</p>
                  <p style="margin-top: -0.5rem">Please choose another time or option</p>
                </td>
              </tr>
              <tr v-else v-for="(dealerData, dealerName) in dealerPaginatedData" :key="dealerName">
                <td class="hso-table-body" style="border-bottom: none; border-top: none">{{ dealerName }}</td>
                <td
                  v-for="(year, index) in optionYears.slice(1)"
                  :key="index"
                  v-if="!selectedYearDealer || selectedYearDealer === 'All Years' || selectedYearDealer === year"
                  class="hso-table-body"
                  style="border-bottom: none; border-top: none"
                >
                  <template v-if="year === optionYears[optionYears.length - 2]">
                    {{ getDealerValueForOlderYears(dealerData, parseInt(year.replace("<=", ""))) }}
                  </template>
                  <template v-else>
                    {{ getDealerValueForYear(dealerData, year) }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- New ID Dealer Pagination -->
          <div class="d-flex justify-content-between align-items-center mt-3">
            <div class="hso-table-pagination">Showing {{ dealerPaginationStart + 1 }} to {{ dealerPaginationEnd }} of {{ filteredDealerTotalItems }} entries</div>
            <b-pagination v-model="dealerCurrentPage" :total-rows="filteredDealerTotalItems" :per-page="dealerRowsPerPage" aria-controls="dealer-table"></b-pagination>
          </div>
        </div>
      </b-card>

      <!-- Existing ID Start -->
      <div class="row mb-4" v-if="registrationType === 'Existing ID'">
        <div class="col-md-8">
          <div
            class="card d-flex flex-column relative"
            :class="{
              '': registrationType != 'Existing ID',
              'new-id-production-year': registrationType == 'Existing ID',
            }"
          >
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <h5 class="card-title mb-0 mr-2">{{ registrationType }}</h5>
                <div class="tooltip-container">
                  <span><i class="fas fa-info-circle"></i></span>
                  <div class="tooltip">
                    <div class="tooltip-content">Kendaraan yang pertama kali melakukan servis di H2</div>
                  </div>
                </div>
                <div class="d-flex ml-auto">
                  <div class="d-flex ml-auto">
                    <Multiselect v-model="selectedChartStacked" class="mt-1" :options="optionChartStacked" track-by="loc_code" label="loc_code" placeholder="All Dealers" style="width: 150px"></Multiselect>
                    <button class="btn btn-outline-secondary ml-2 mt-1" @click="downloadExcelChartStacked()" style="width: 150px; height: 40px">
                      Download CSV
                      <i class="bi bi-download"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="isLoadingChartStacked" class="loading text-center h-100 d-flex align-items-center justify-content-center">
                <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
              </div>
              <existing-id-chart-stacked-column
                v-else="dataChartStacked"
                :start-date="dateRange.start"
                :end-date="dateRange.end"
                :selected-year-global="selectedYearGlobal"
                :selected-chart-dealer="selectedChartStacked"
                :chart-data="dataChartStacked"
              />

              <!-- Pass Existing ID -->
            </div>
          </div>
        </div>

        <div class="col-md-4" v-if="registrationType === 'Existing ID'">
          <div
            class="card d-flex flex-column relative"
            :class="{
              '': registrationType != 'Existing ID',
              'new-id-production-year': registrationType == 'Existing ID',
            }"
          >
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <h6 class="card-title mb-0 mr-2">Overall by Production Year</h6>
                <div class="tooltip-container">
                  <span><i class="fas fa-info-circle"></i></span>
                  <div class="tooltip">
                    <div class="tooltip-content">Presentase data Existing ID berdasarkan tahun produksi</div>
                  </div>
                </div>
                <div class="d-flex ml-auto">
                  <button class="btn btn-outline-secondary ml-1 mt-1" @click="downloadExcelOverallProductionYear()" style="width: 150px; height: 40px">
                    Download CSV
                    <i class="bi bi-download"></i>
                  </button>
                </div>
              </div>
              <div v-if="isLoadingOverallProductionYear" class="loading text-center h-100 d-flex align-items-center justify-content-center">
                <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
              </div>
              <existing-id-chart-overall-production-year
                v-else="dataChartOverallProductionYear"
                :start-date="dateRange.start"
                :end-date="dateRange.end"
                :selected-year-global="selectedYearGlobal"
                :chart-data="dataChartOverallProductionYear"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-4 relative" v-if="registrationType === 'Existing ID' && showPerformanceYear">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <h5 class="card-title mb-0 mr-2">Perfomance Comparison</h5>
            <div class="tooltip-container">
              <span><i class="fas fa-info-circle"></i></span>
              <div class="tooltip">
                <div class="tooltip-content">Perbandingan antar tahun data kendaraan yang melakukan pergantian QR di H2</div>
              </div>
            </div>
            <div class="d-flex ml-auto">
              <div class="filter-date icon fa-calendar mr-3">
                <flat-pickr v-model="dateRange.date" :config="rangeConfig" class="mt-0 mb-0" placeholder="Select Date" />
              </div>

              <h5 class="mr-3">and</h5>

              <div class="filter-date icon fa-calendar mr-3">
                <flat-pickr v-model="dateRange.date" :config="rangeConfig" class="mt-0 mb-0" placeholder="Select Date" />
              </div>
              <Multiselect class="mt-1 ml-2" v-model="selectedSegment" :options="optionSegments" :multiple="true" placeholder="region" style="width: 200px"></Multiselect>
              <button class="btn btn-outline-secondary ml-2" @click="downloadExcelSegment()" style="width: 200px; height: 40px; margin-top: 4px">
                Download CSV
                <i class="bi bi-download"></i>
              </button>
            </div>
          </div>
          <div v-if="isLoadingChartStacked" class="loading text-center h-100 d-flex align-items-center justify-content-center">
            <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
          </div>
          <existing-id-chart-perfomance-comparison :start-date="dateRange.start" :end-date="dateRange.end" />
        </div>
      </div>
      <!-- Tabel Existing Segment -->
      <div class="card mb-4 relative" v-if="registrationType === 'Existing ID'">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <h5 class="card-title mb-0 mr-2">Segment</h5>
            <div class="tooltip-container">
              <span><i class="fas fa-info-circle"></i></span>
              <div class="tooltip">
                <div class="tooltip-title">Segment</div>
                <div class="tooltip-content">Kendaraan yang pertama kali melakukan servis di H2 dilihat berdasarkan segmentasi motor</div>
              </div>
            </div>
            <div class="d-flex ml-auto">
              <Multiselect class="mt-1 ml-2" v-model="selectedSegment" :options="optionSegments" :multiple="true" placeholder="Select Segments" style="width: 200px"></Multiselect>
              <Multiselect class="mt-1 ml-2" v-model="selectedYearSegment" :options="optionYears" placeholder="All Years" style="width: 200px"></Multiselect>
              <button class="btn btn-outline-secondary ml-2" @click="downloadExcelSegment()" style="width: 200px; height: 40px; margin-top: 4px">
                Download CSV
                <i class="bi bi-download"></i>
              </button>
            </div>
          </div>
          <div v-if="isLoadingSegment" class="loading text-center h-100 d-flex align-items-center justify-content-center">
            <img src="/img/loading.gif" width="48" height="48" alt="Loading New ID" />
          </div>
          <existing-id-chart-segment
            v-else="dataSegment"
            ref="segmentChart"
            :start-date="dateRange.start"
            :end-date="dateRange.end"
            :selected-year-segment="selectedYearSegment"
            :selected-segments="selectedSegment"
            :chart-data="dataSegment"
          />
        </div>
      </div>
      <!-- Tabel Existing Region -->
      <div class="card mb-4 relative card-table" v-if="registrationType === 'Existing ID'">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <h5 class="card-title mb-0 mr-2">{{ registrationType }} by Region</h5>
            <div class="tooltip-container">
              <span><i class="fas fa-info-circle"></i></span>
              <div class="tooltip">
                <div class="tooltip-content">Kendaraan yang pertama kali melakukan servis di H2 berdasarkan lokasi region</div>
              </div>
            </div>
            <div class="d-flex ml-auto">
              <Multiselect v-model="selectedRegion" :options="optionRegions" placeholder="Semua MD" class="mt-1 ml-2" style="width: 200px"></Multiselect>
              <Multiselect v-model="selectedYearRegion" :options="optionYears" placeholder="All Years" class="mt-1 ml-2" style="width: 200px"></Multiselect>
              <button class="btn btn-outline-secondary ml-2 align-self-end" @click="downloadExcelRegion()" style="width: 150px; height: 40px">
                Download CSV
                <i class="bi bi-download"></i>
              </button>
            </div>
          </div>
          <div v-if="isLoadingRegion" class="loading text-center h-100 d-flex align-items-center justify-content-center">
            <img src="/img/loading.gif" width="48" height="48" :alt="`Loading ${registrationType} Region`" />
          </div>
          <div v-else>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="hso-table-header" style="background-color: #ffffff">Region Name</th>
                  <th v-for="month in displayedMonths" :key="month" class="hso-table-header" style="background-color: #ffffff">
                    {{ month }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="dataRegion.length === 0">
                  <td colspan="100%" style="padding: 2rem 0">
                    <p style="font-weight: bold; font-size: 1.5rem; line-height: 1">No Data Available.</p>
                    <p style="margin-top: -0.5rem">Please choose another time or option</p>
                  </td>
                </tr>
                <tr v-else v-for="(regionData, regionName) in paginatedData" :key="regionName">
                  <td class="hso-table-body" style="border-bottom: none; border-top: none">{{ regionName }}</td>
                  <td v-for="month in displayedMonths" :key="month" class="hso-table-body" style="border-bottom: none; border-top: none">
                    {{ getValueForMonth(regionData, month) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- New ID Region Pagination -->
            <div class="d-flex justify-content-between align-items-center mt-3">
              <div>Showing {{ paginationStart + 1 }} to {{ paginationEnd }} of {{ totalItems }} entries</div>
              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="rowsPerPage" aria-controls="replacement-table"></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- Tabel Dealer Existing -->
      <b-card class="mb-4 relative card-table" v-if="registrationType === 'Existing ID'">
        <div class="d-flex align-items-center mb-3">
          <h5 class="card-title mb-0 mr-2">{{ registrationType }} by Dealer</h5>
          <div class="tooltip-container">
            <span><i class="fas fa-info-circle"></i></span>
            <div class="tooltip">
              <div class="tooltip-content">Kendaraan yang pertama kali melakukan servis di H2 berdasarkan lokasi dealer</div>
            </div>
          </div>
          <div class="d-flex ml-auto">
            <Multiselect v-model="selectedDealer" class="mt-1 ml-2" :options="optionDealers" placeholder="All Dealer" style="width: 200px"></Multiselect>
            <Multiselect v-model="selectedYearDealer" class="mt-1 ml-2" :options="optionYears" placeholder="All Years" style="width: 200px" @change="selectedYearDealer"></Multiselect>
            <button class="region-download btn btn-outline-secondary ml-2" @click="downloadExcelDealer()" style="width: 150px; height: 40px; margin-top: 4px">
              Download CSV
              <i class="bi bi-download"></i>
            </button>
          </div>
        </div>
        <div v-if="isLoadingDealer" class="loading text-center h-100 d-flex align-items-center justify-content-center">
          <img src="/img/loading.gif" width="48" height="48" :alt="`Loading ${registrationType} Dealer`" />
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th class="hso-table-header" style="background-color: #ffffff">Dealer Name</th>
                <th v-for="(year, index) in optionYears.slice(1)" :key="index" v-if="!selectedYearDealer || selectedYearDealer === 'All Years' || selectedYearDealer === year" class="hso-table-header" style="background-color: #ffffff">
                  {{ year }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="dataDealer.length === 0">
                <td colspan="100%" style="padding: 2rem 0">
                  <p style="font-weight: bold; font-size: 1.5rem; line-height: 1">No Data Available.</p>
                  <p style="margin-top: -0.5rem">Please choose another time or option</p>
                </td>
              </tr>
              <tr v-else v-for="(dealerData, dealerName) in dealerPaginatedData" :key="dealerName">
                <td class="hso-table-body" style="border-bottom: none; border-top: none">{{ dealerName }}</td>
                <td
                  v-for="(year, index) in optionYears.slice(1)"
                  :key="index"
                  v-if="!selectedYearDealer || selectedYearDealer === 'All Years' || selectedYearDealer === year"
                  class="hso-table-body"
                  style="border-bottom: none; border-top: none"
                >
                  <template v-if="year === optionYears[optionYears.length - 2]">
                    {{ getDealerValueForOlderYears(dealerData, parseInt(year.replace("<=", ""))) }}
                  </template>
                  <template v-else>
                    {{ getDealerValueForYear(dealerData, year) }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- New ID Dealer Pagination -->
          <div class="d-flex justify-content-between align-items-center mt-3">
            <div class="hso-table-pagination">Showing {{ dealerPaginationStart + 1 }} to {{ dealerPaginationEnd }} of {{ filteredDealerTotalItems }} entries</div>
            <b-pagination v-model="dealerCurrentPage" :total-rows="filteredDealerTotalItems" :per-page="dealerRowsPerPage" aria-controls="dealer-table"></b-pagination>
          </div>
        </div>
      </b-card>

      <!-- End -->
    </div>
  </div>
</template>

<script>
import qs from "qs";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import Multiselect from "vue-multiselect";
import { FoldSpinner } from "vue-spinners";
import NewIdChartStackedColumn from "./childComponents/NewIdChartStackedColumn";
import NewIdChartOverallProductionYear from "./childComponents/NewIdChartOverallProductionYear";
import NewIdChartSegment from "./childComponents/NewIdChartSegment";
import ExistingIdChartStackedColumn from "./childComponents/ExistingIdChartStackedColumn";
import ExistingIdChartOverallProductionYear from "./childComponents/ExistingIdChartOverallProductionYear";
import ExistingIdChartSegment from "./childComponents/ExistingIdChartSegment";
import Tooltip from "./childComponents/Tooltip.vue";
import XLSX from "xlsx";
import { ToggleButton } from "vue-js-toggle-button";
import NewIdChartPerfomanceComparison from "./childComponents/NewIdChartPerfomanceComparison.vue";
import ExistingIdChartPerfomanceComparison from "./childComponents/ExistingIdChartPerfomanceComparison.vue";

export default {
  name: "q-registration",
  metaInfo: {
    title: "QR Registration",
  },
  data() {
    return {
      registrationType: "New ID",
      cachedData: {
        "New ID": {}, // Cache for New ID
        "Existing ID": {}, // Cache for Existing ID
      },
      // Top Navigation
      rangeConfig: {
        mode: "range",
        altInput: true,
        altInputClass: "form-control input elegant-flatpickr",
        dateFormat: "Y-m-d",
        onChange: this.onDateChange,
        defaultDate: null,
        date_filter: true,
        altFormat: "M j, Y",
        locale: {
          months: {
            shorthand: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            longhand: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          },
        },
      },
      dateRange: {
        date: null,
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      //toggle
      showPerformanceYear: false,
      isContainerVisible: false, // Controls whether the new container is visible

      optionYears: [],
      selectedYearGlobal: null,

      // Chart Stacked
      isLoadingChartStacked: false,
      optionChartStacked: [],
      selectedChartStacked: null,
      dataChartStacked: null,

      // Chart Overall Production Year
      isLoadingOverallProductionYear: false,
      dataChartOverallProductionYear: null,

      //Chart Comparisom

      // Segments
      isLoadingSegment: false,
      optionSegments: [],
      selectedSegment: null,
      selectedYearSegment: null, // This is for the Segment section
      dataSegment: null,

      // Regions
      isLoadingRegion: false,
      optionRegions: [],
      selectedRegion: null,
      selectedYearRegion: null,
      dataRegion: null,

      // Dealers
      isLoadingDealer: false,
      optionDealers: [],
      selectedDealer: null,
      selectedYearDealer: null,
      dataDealer: {},
      dealerCurrentPage: 1,
      dealerRowsPerPage: 20,

      isLoading: false,
      fetchedTabs: {},

      months: ["January", "February", "March", "April", "May", "June", "July"],
      displayedMonths: [],

      currentPage: 1,
      rowsPerPage: 20,
    };
  },
  watch: {
    "dateRange.start": function () {
      this.fetchRegistrationChartStacked();
      this.fetchRegistrationOverallProductionYear();
      this.fetchRegistrationSegment();
      this.fetchRegistrationRegion();
      this.fetchRegistrationDealer();
    },
    "dateRange.end": function () {
      this.fetchRegistrationChartStacked();
      this.fetchRegistrationOverallProductionYear();
      this.fetchRegistrationSegment();
      this.fetchRegistrationRegion();
      this.fetchRegistrationDealer();
    },
    selectedChartStacked(newDealer) {
      this.fetchRegistrationChartStacked();
    },
    selectedSegment() {
      this.fetchRegistrationSegment();
    },
    selectedYearSegment() {
      this.fetchRegistrationSegment();
    },
    selectedRegion() {
      this.fetchRegistrationRegion();
      this.currentPage = 1;
    },
    selectedYearRegion() {
      this.fetchRegistrationRegion();
      this.currentPage = 1;
    },
    selectedDealer() {
      this.resetPagination();
    },
    selectedYearDealer(year) {
      // console.log(year);
      this.fetchRegistrationDealer();
      this.dealerCurrentPage = 1;
    },
  },
  components: {
    flatPickr,
    Multiselect,
    fold: FoldSpinner,
    NewIdChartStackedColumn,
    NewIdChartOverallProductionYear,
    NewIdChartSegment,
    ExistingIdChartStackedColumn,
    ExistingIdChartOverallProductionYear,
    ExistingIdChartSegment,
    Tooltip,
    ToggleButton,
    ExistingIdChartPerfomanceComparison,
    NewIdChartPerfomanceComparison,
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return Object.fromEntries(Object.entries(this.filteredRegistrationData).slice(start, end));
    },
    totalItems() {
      return Object.keys(this.filteredRegistrationData && this.filteredRegistrationData).length;
    },
    paginationStart() {
      return (this.currentPage - 1) * this.rowsPerPage;
    },
    paginationEnd() {
      return Math.min(this.paginationStart + this.rowsPerPage, this.totalItems);
    },

    dealerPaginatedData() {
      const start = (this.dealerCurrentPage - 1) * this.dealerRowsPerPage;
      const end = start + this.dealerRowsPerPage;
      return Object.fromEntries(Object.entries(this.filteredDealerData).slice(start, end));
    },
    dealerTotalItems() {
      return Object.keys(this.dataDealer).length;
    },
    dealerPaginationStart() {
      return (this.dealerCurrentPage - 1) * this.dealerRowsPerPage;
    },
    dealerPaginationEnd() {
      return Math.min(this.dealerPaginationStart + this.dealerRowsPerPage, this.filteredDealerTotalItems);
    },
    optionDealers() {
      return this.dataDealer ? Object.keys(this.dataDealer) : [];
    },
    filteredDealerData() {
      let filtered = { ...this.dataDealer };

      if (this.selectedDealer) {
        filtered = {
          [this.selectedDealer]: filtered[this.selectedDealer],
        };
      }

      if (yearSelected === "All Years") {
        return filtered; // Return all filtered data for all years
      }

      const yearSelected = this.selectedYearDealer;
      if (yearSelected && yearSelected !== "All Years") {
        Object.keys(filtered).forEach((dealer) => {
          if (Array.isArray(filtered[dealer])) {
            filtered[dealer] = filtered[dealer].filter((item) => {
              const lastYearInOptions = this.optionYears[this.optionYears.length - 2];
              const cutoffYear = parseInt(lastYearInOptions.replace("<=", ""));

              if (this.selectedYearDealer === lastYearInOptions) {
                return item.year_group === "Older" || parseInt(item.year_group) <= cutoffYear;
              }
              return item.year_group.toString() === this.selectedYearDealer.toString();
            });
          } else {
            filtered[dealer] = []; // Set to empty array if no data
          }
        });
      }

      return filtered;
    },

    filteredDealerTotalItems() {
      return Object.values(this.filteredDealerData).reduce((total, dealerData) => total + (dealerData.length || 0), 0);
    },

    filteredRegistrationData() {
      if (!this.selectedRegion) {
        return this.dataRegion;
      }
      return {
        [this.selectedRegion]: this.dataRegion[this.selectedRegion],
      };
    },
  },
  mounted() {
    this.switchRegistrationType(this.registrationType); // fetch data for first init
    this.getYears();
  },
  methods: {
    //bayu
    // Handle toggle change
    onToggleChange(value) {
      this.showPerformanceYear = value; // Update status toggle

      // Fetch data berdasarkan status toggle
      this.fetchRegistrationChartStacked();
      this.fetchRegistrationOverallProductionYear();
      this.fetchRegistrationSegment();
      this.fetchRegistrationRegion();
      this.fetchRegistrationDealer();
    },

    switchRegistrationType(type) {
      // console.log(`Switching to: ${type}`);
      this.registrationType = type;

      // Reset data when switching tabs
      this.dataChartStacked = null;
      this.dataChartOverallProductionYear = null;
      this.dataSegment = null;
      this.dataRegion = null;
      this.dataDealer = {};

      // Define selectedYear based on the section, prioritizing dealer-specific year
      const selectedYear = this.selectedYearDealer || this.selectedYearRegion || this.selectedYearSegment || this.selectedYearGlobal;

      // console.log("Selected Year for Cache:", selectedYear);
      // console.log("Current Cached Data for Registration Type:", this.cachedData[this.registrationType]);

      // Load cached data if available
      const cachedData = this.cachedData[this.registrationType];
      const cached = cachedData[selectedYear];

      if (cached) {
        // Ensure the cached properties exist before accessing them
        this.dataChartStacked = cached.dataChartStacked || [];
        this.dataChartOverallProductionYear = cached.dataChartOverallProductionYear || [];
        this.dataSegment = cached.dataSegment || [];
        this.dataRegion = cached.dataRegion || {};
        this.dataDealer = cached.dataDealer || {};

        // console.log("Loaded from cache - Stacked Chart:", this.dataChartStacked);
        // console.log("Loaded from cache - Overall Production Year Chart:", this.dataChartOverallProductionYear);
        // console.log("Loaded from cache - Segment:", this.dataSegment);
        // console.log("Loaded from cache - Region:", this.dataRegion);
        // console.log("Loaded from cache - Dealer:", this.dataDealer);
      } else {
        // console.log("No cache found, fetching data...");
        this.fetchRegistrationChartStacked();
        this.fetchRegistrationChartStackedOptions();
        this.fetchRegistrationOverallProductionYear();
        this.fetchRegistrationSegment();
        this.fetchOptionSegments();
        this.fetchRegistrationRegion();
        this.fetchRegistrationDealer();
      }
    },
    selectGlobalYear() {
      // Trigger fetching for each section individually based on their years
      this.fetchRegistrationChartStacked();
      this.fetchRegistrationOverallProductionYear();
      // this.fetchRegistrationDealer();

      // When global year changed, also change each section year.
      this.selectedYearSegment = this.selectedYearGlobal;
      this.selectedYearRegion = this.selectedYearGlobal;
      this.selectedYearDealer = this.selectedYearGlobal;
      // console.log(`Parent selectedYearGlobal: ${this.selectedYearGlobal}`);
    },

    onYearSelected(selectedYear) {
      this.selectedYearDealer = selectedYear;
      this.resetPagination(); // Reset pagination when a year is selected
    },
    resetPagination() {
      this.dealerCurrentPage = 1; // Reset to first page
    },

    getYears() {
      const currentYear = new Date().getFullYear();
      const cutoffYear = currentYear - 3; // Dynamically calculate cutoff year for older years
      this.optionYears = [
        "All Years",
        currentYear, // Current year
        currentYear - 1, // One year prior
        currentYear - 2, // Two years prior
        `<=${cutoffYear}`, // Dynamically generate older year group
        `Unknown`,
      ];
    },

    // begin stacked column chart
    fetchRegistrationChartStacked() {
      this.isLoadingChartStacked = true; // Show loading indicator

      const selectedYear = this.selectedYearGlobal; // Use global year as there's no specific per section year

      // Ensure cache is initialized for registration type and year
      if (!this.cachedData[this.registrationType]) {
        this.cachedData[this.registrationType] = {};
      }

      if (!this.cachedData[this.registrationType][selectedYear]) {
        this.cachedData[this.registrationType][selectedYear] = {};
      }

      const params = {};

      // Add parameters based on user selection
      if (selectedYear !== "All Years") {
        params.manufacture_year = selectedYear;
      }
      if (this.dateRange.start) {
        params.start = this.dateRange.start;
      }
      if (this.dateRange.end) {
        params.end = this.dateRange.end;
      }
      // console.log("SelectedChartStacked:", this.selectedChartStacked);

      // Ensure the cache is initialized for selectedDealer
      if (this.selectedChartStacked) {
        params.dealer = this.selectedChartStacked.loc_code; // Use only dealer code from selectedChartStacked
        // console.log("Selected dealer code (loc_code):", params.dealer);
      }
      // Menambahkan toggle untuk parameter fromCache
      if (this.showPerformanceYear) {
        params.fromCache = true; // Toggle aktif
      } else {
        params.fromCache = false; // Toggle nonaktif
      }

      // Define the URL based on the registration type
      let url;
      if (this.registrationType === "New ID") {
        url = "/api/distribution/qr-registration/new-id";
      } else {
        url = "/api/distribution/qr-registration/existing-id";
      }

      // Proceed with the API call
      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.status === "success") {
            // Transform the raw data
            this.dataChartStacked = this.convertToChartData(response.data.data, selectedYear);

            // Cache the transformed data for future use
            this.cachedData[this.registrationType][selectedYear].dataChartStacked = this.dataChartStacked;
            this.isLoadingChartStacked = false; // Hide loading indicator
          } else {
            // console.error("Error fetching data:", response.data.message);
          }
        })
        .catch((error) => {
          // console.error("Error fetching stacked chart data:", error);
          this.isLoadingChartStacked = false; // Hide loading indicator in case of error
        });
    },

    // Transform API data into stacked new id existing id data
    convertToChartData(rawData, selectedYear = null) {
      const chartData = [];

      Object.keys(rawData).forEach((month) => {
        const cleanedMonth = month.replace("2024", "").trim(); // Clean month format
        const monthData = rawData[month]; // Data for the specific month
        const transformedData = { month: cleanedMonth }; // Initialize object with the cleaned month

        // Filter based on selectedYear:
        // Include only "Unknown" if selectedYear is "Unknown".
        // Exclude "Unknown" if selectedYear is a specific year other than "All Years".
        const filteredMonthData = monthData.filter((data) => {
          const key = data.year_group || data.segment || "Unknown";
          return (selectedYear === "Unknown" && key === "Unknown") || selectedYear === "All Years" || key !== "Unknown";
        });

        if (filteredMonthData.length > 0) {
          filteredMonthData.forEach((data) => {
            const key = data.year_group || data.segment || "Unknown";
            transformedData[key] = data.value;
          });
          chartData.push(transformedData);
        }
      });

      return chartData.length ? chartData : [{}];
    },
    fetchRegistrationChartStackedOptions() {
      axios
        .get("/api/master/regions")
        .then((response) => {
          if (response.data.status === "success") {
            this.optionChartStacked = response.data.data.map((dealer) => ({
              loc_code: dealer.loc_code,
              name: dealer.name,
            }));
          } else {
            // console.error("Error fetching chart dealer options:", response.data.message);
          }
        })
        .catch((error) => {
          // console.error("Error fetching chart dealer options:", error);
        });
    },

    onChartDealerChange(value) {
      this.selectedChartStacked = value; // Ensure selectedChartStacked is updated
      // console.log("Dealer selected in Multiselect:", value);
    },

    fetchRegistrationOverallProductionYear() {
      this.isLoadingOverallProductionYear = true; // Show loading indicator
      const selectedYear = this.selectedYearGlobal; // Use global year for fetching

      // Ensure cache is initialized for registration type and year
      if (!this.cachedData[this.registrationType]) {
        this.cachedData[this.registrationType] = {};
      }
      if (!this.cachedData[this.registrationType][selectedYear]) {
        this.cachedData[this.registrationType][selectedYear] = {};
      }

      const params = {};
      // Add parameters based on user selection
      if (selectedYear !== "All Years") {
        params.manufacture_year = selectedYear;
      }
      if (this.dateRange.start) {
        params.start = this.dateRange.start;
      }
      if (this.dateRange.end) {
        params.end = this.dateRange.end;
      }

      let url;
      if (this.registrationType === "New ID") {
        url = "/api/distribution/qr-registration/new-id-by-production-year";
      } else {
        url = "/api/distribution/qr-registration/existing-id-by-production-year";
      }

      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.status === "success") {
            this.dataChartOverallProductionYear = response.data.data; // Update chart data with fetched data
            this.isLoadingOverallProductionYear = false; // Hide loading indicator
            // Cache the fetched data
            this.cachedData[this.registrationType][selectedYear].dataChartOverallProductionYear = response.data.data;
          } else {
            // console.error("Error fetching data:", response.data.message);
            this.isLoadingOverallProductionYear = false; // Hide loading indicator in case of error
          }
        })
        .catch((error) => {
          // console.error("Error fetching production year data:", error);
          this.isLoadingOverallProductionYear = false; // Hide loading indicator in case of error
        });
    },

    fetchOptionSegments() {
      this.isLoading = true; // Show loading indicator

      // Ensure cache is initialized for registration type
      if (!this.cachedData[this.registrationType]) {
        this.cachedData[this.registrationType] = {};
      }

      // Check if segment options are already cached for the registration type
      if (this.cachedData[this.registrationType].optionSegments) {
        this.optionSegments = this.cachedData[this.registrationType].optionSegments;
        this.isLoading = false;
        return;
      }

      this.$http
        .get("/api/master/segment")
        .then((response) => {
          if (response.data.status === "success") {
            this.optionSegments = response.data.data.map((item) => item.segment);

            // Cache the fetched data
            this.cachedData.optionSegments = this.optionSegments;

            this.isLoading = false; // Hide loading indicator
          } else {
            // console.error("Error fetching segment options:", response.data.message);
            this.isLoading = false; // Hide loading indicator in case of error
          }
        })
        .catch((error) => {
          // console.error("Error fetching segment options:", error);
          this.isLoading = false; // Hide loading indicator in case of error
        });
    },
    fetchRegistrationSegment() {
      this.isLoadingSegment = true; // Show loading indicator
      // const selectedYear = this.selectedYearSegment || this.selectedYearGlobal;
      const selectedYear = this.selectedYearSegment === null ? null : this.selectedYearSegment || this.selectedYearGlobal;

      // Ensure cache is initialized for registration type and year
      if (!this.cachedData[this.registrationType]) {
        this.cachedData[this.registrationType] = {};
      }
      if (!this.cachedData[this.registrationType][selectedYear]) {
        this.cachedData[this.registrationType][selectedYear] = {};
      }
      const params = {};
      // Add parameters based on user selection
      if (selectedYear !== "All Years") {
        params.manufacture_year = selectedYear;
      }
      if (this.dateRange.start) {
        params.start = this.dateRange.start;
      }
      if (this.dateRange.end) {
        params.end = this.dateRange.end;
      }
      if (this.selectedSegment && this.selectedSegment.length > 0) {
        params.segment = this.selectedSegment.join(","); // Use joined segments
      }
      // Tambahkan parameter toggle
      params.fromCache = this.showPerformanceYear;

      let url;
      if (this.registrationType === "New ID") {
        url = "/api/distribution/qr-registration/new-id-by-segment";
      } else {
        url = "/api/distribution/qr-registration/existing-id-by-segment";
      }

      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.status === "success") {
            this.dataSegment = this.convertToChartData(response.data.data); // Convert to chart data so it'll be chart friendly
            // console.log("Transformed Segment Data:", this.dataSegment);
            this.isLoadingSegment = false; // Hide loading indicator
            // Cache the fetched data
            this.cachedData[this.registrationType][selectedYear].dataSegment = this.dataSegment;
          } else {
            // console.error("Error fetching segment data:", response.data.message);
            this.isLoading = false; // Hide loading indicator in case of error
          }
        })
        .catch((error) => {
          // console.error("Error fetching segment data:", error);
          this.isLoading = false; // Hide loading indicator in case of error
        });
    },
    fetchRegistrationRegion() {
      this.isLoadingRegion = true; // Show loading indicator
      this.dataRegion = ""; // Reset to an empty string to avoid stale data

      // const selectedYear = this.selectedYearRegion || this.selectedYearGlobal;
      const selectedYear = this.selectedYearRegion === null ? null : this.selectedYearRegion || this.selectedYearGlobal;

      // Ensure cache is initialized for registration type and year
      if (!this.cachedData[this.registrationType]) {
        this.cachedData[this.registrationType] = {};
      }
      if (!this.cachedData[this.registrationType][selectedYear]) {
        this.cachedData[this.registrationType][selectedYear] = {};
      }

      const params = {};
      if (selectedYear !== "All Years") params.manufacture_year = selectedYear;
      if (this.dateRange.start) params.start = this.dateRange.start;
      if (this.dateRange.end) params.end = this.dateRange.end;
      if (this.selectedRegion) params.region = this.selectedRegion.split(" - ")[0];
      // Menambahkan toggle untuk parameter fromCache
      if (this.showPerformanceYear) {
        params.fromCache = true; // Toggle aktif
      } else {
        params.fromCache = false; // Toggle nonaktif
      }
      const url = this.registrationType === "New ID" ? "/api/distribution/qr-registration/new-id-by-region" : "/api/distribution/qr-registration/existing-id-by-region";
      // console.log(params);

      this.$http
        .get(url, { params })
        .then((response) => {
          this.isLoadingRegion = false;

          if (response.data.status === "success" && response.data.data) {
            this.dataRegion = response.data.data;

            if (!params.region) {
              this.optionRegions = Object.keys(this.dataRegion); // Store the keys
            }

            if (this.optionRegions.length === 0) {
              this.dataRegion = ""; // Handle empty response
            } else {
              // Cache the data
              this.cachedData[this.registrationType][selectedYear].dataRegion = this.dataRegion;
            }

            this.currentPage = 1;
            this.updateDisplayedMonths(); // Update months display
          } else {
            // console.error("Error fetching data:", response.data.message);
            this.isLoadingRegion = false;
            this.dataRegion = ""; // Handle API error
          }
        })
        .catch((error) => {
          // console.error("Error fetching registration region data:", error);
          this.isLoadingRegion = false;
          this.dataRegion = ""; // Handle fetch error
        });
    },

    updateDisplayedMonths() {
      if (Object.keys(this.dataRegion).length > 0) {
        const firstRegionData = this.dataRegion[Object.keys(this.dataRegion)[0]];
        this.displayedMonths = firstRegionData
          .map((item) => item.bulan)
          .sort((a, b) => {
            const monthOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return monthOrder.indexOf(a) - monthOrder.indexOf(b);
          });
      }
    },
    onDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        this.dateRange.start = moment(selectedDates[0]).format("YYYY-MM-DD");
        this.dateRange.end = moment(selectedDates[1]).format("YYYY-MM-DD");
      }
    },

    getValueForMonth(regionData, month) {
      const monthData = regionData.find((item) => item.bulan === month);
      return monthData ? monthData.value.toLocaleString() : "-";
    },
    fetchRegistrationDealer() {
      this.isLoadingDealer = true; // Show loading indicator
      this.dataDealer = []; // Reset to an empty array to avoid stale data
      this.dealerCurrentPage = 1;

      // const selectedYear = this.selectedYearDealer || this.selectedYearGlobal;
      const selectedYear = this.selectedYearDealer === null ? null : this.selectedYearDealer || this.selectedYearGlobal;
      // console.log(selectedYear);
      // Ensure cache is initialized for registration type and year
      if (!this.cachedData[this.registrationType]) {
        this.cachedData[this.registrationType] = {};
      }
      if (!this.cachedData[this.registrationType][selectedYear]) {
        this.cachedData[this.registrationType][selectedYear] = {};
      }

      const params = {};
      if (selectedYear) params.manufacture_year = selectedYear;
      if (this.dateRange.start) params.start = this.dateRange.start;
      if (this.dateRange.end) params.end = this.dateRange.end;
      if (this.selectedDealer) params.dealer = this.selectedDealer.split(" - ")[0];

      // Tambahkan nilai `fromCache` berdasarkan status toggle
      // params.fromCache = this.selected.date_filter ? true : false;

      const url = this.registrationType === "New ID" ? "/api/distribution/qr-registration/new-id-by-dealer" : "/api/distribution/qr-registration/existing-id-by-dealer";

      this.$http
        .get(url, { params })
        .then((response) => {
          this.isLoadingDealer = false; // Stop loading indicator

          if (response.data.status === "success" && response.data.data) {
            this.dataDealer = response.data.data; // Populate data

            if (!params.dealer) {
              this.optionDealers = Object.keys(this.dataDealer); // Store the keys
            }

            if (this.optionDealers.length === 0) {
              this.dataDealer = []; // Handle empty response
            } else {
              // Cache the data
              this.cachedData[this.registrationType][selectedYear].dataDealer = this.dataDealer;
            }

            this.dealerCurrentPage = 1;
            this.updateDisplayedMonths(); // Update months display
          } else {
            // console.error("Error fetching data:", response.data.message);
            this.isLoadingDealer = false;
            this.dataDealer = []; // Reset to empty array on error
            this.optionDealers = []; // Clear optionDealers on error
          }
        })
        .catch((error) => {
          // console.error("Error fetching dealer data:", error);
          this.isLoadingDealer = false; // Stop loading
          this.dataDealer = []; // Handle fetch error
          this.optionDealers = []; // Clear optionDealers on fetch error
        });
    },
    getDealerValueForYear(dealerData, year) {
      if (year === "All Years") {
        return null;
      }
      if (!dealerData || !Array.isArray(dealerData)) {
        // console.log("Invalid dealer data for year:", year);
        return "0";
      }

      // Convert both year_group and year to strings for comparison
      const yearData = dealerData.find((item) => item.year_group.toString() === year.toString());

      return yearData ? yearData.value.toLocaleString() : "0";
    },
    getDealerValueForOlderYears(dealerData, cutoffYear) {
      if (!dealerData || !Array.isArray(dealerData)) {
        return "0"; // Return 0 if no data is available
      }

      // Filter for years that are "Older" or <= cutoffYear
      const olderYears = dealerData.filter((item) => item.year_group === "Older" || parseInt(item.year_group) <= cutoffYear);

      // Sum the values of all older years
      const total = olderYears.reduce((sum, item) => sum + item.value, 0);

      return total.toLocaleString(); // Return the total sum as a formatted string
    },

    selectedYearDealer(year) {
      // Check if the user has unselected the year
      if (!year || year === "All Years") {
        // Set the year to "All Years" if no year is selected
        this.selectedYearDealer = "All Years";
        // Fetch all dealer data
        this.fetchRegistrationDealer();
      } else {
        // Update the selected year
        this.selectedYearDealer = year;
        // Fetch dealer data based on the new year selection
        this.fetchRegistrationDealer();
      }

      this.dealerCurrentPage = 1; // Reset current page
    },
    showTooltip() {
      this.$refs.tooltip.showTooltip();
    },
    hideTooltip() {
      this.$refs.tooltip.hideTooltip();
    },

    async downloadExcelChartStacked() {
      try {
        let selectedYear = this.selectedYearGlobal; // Use global year
        const currentYear = new Date().getFullYear(); // Get the current year

        // If no year is selected on initialization, use "All Years" by default
        if (!selectedYear) {
          selectedYear = "All Years";
        }

        const params = {};

        // Add params only if the user selected a year
        if (selectedYear && selectedYear !== "All Years") {
          params.manufacture_year = selectedYear;
        }
        if (this.dateRange.start) {
          params.start = this.dateRange.start;
        }
        if (this.dateRange.end) {
          params.end = this.dateRange.end;
        }

        // Handle selectedChartStacked (use loc_code for selected dealer)
        let isDealerSelected = false;
        if (this.selectedChartStacked && this.selectedChartStacked.loc_code) {
          params.dealer = this.selectedChartStacked.loc_code; // Use the loc_code from selectedChartStacked
          isDealerSelected = true;
        }

        // Define the URL based on the registration type (New ID / Existing ID)
        const url = this.registrationType === "New ID" ? "/api/distribution/qr-registration/new-id" : "/api/distribution/qr-registration/existing-id";

        // Fetch data from the API
        const res = await axios.get(url, { params });

        if (res.data && res.data.data) {
          const data = res.data.data;
          const rows = [];

          if (isDealerSelected) {
            // Handle the response when dealer is selected
            const headers = ["Month"];
            const yearGroupsSet = new Set();

            // Collect all unique year groups present in the data
            Object.keys(data).forEach((month) => {
              data[month].forEach((item) => {
                yearGroupsSet.add(item.year_group);
              });
            });

            // Convert Set to Array and sort it (place "Older" last)
            const yearGroups = Array.from(yearGroupsSet).sort((a, b) => {
              if (a === "Older") return 1;
              if (b === "Older") return -1;
              return b - a;
            });

            headers.push(...yearGroups); // Add year groups to headers
            rows.push(headers); // Add headers to rows

            // Iterate over each month and fill in the data
            Object.keys(data).forEach((month) => {
              const cleanedMonth = month.replace(/\s?\d{4}/, "").trim(); // Remove the year (e.g., "2024") from the month
              const row = [cleanedMonth]; // Add the cleaned month as the first column

              // Initialize row data for each year group to 0
              const rowData = {};
              yearGroups.forEach((group) => {
                rowData[group] = 0; // Default to 0
              });

              // Fill in the actual data for the month
              data[month].forEach((item) => {
                rowData[item.year_group] = item.value || 0; // Set the value for the year group
              });

              // Add the row data for each year group to the row
              yearGroups.forEach((group) => {
                row.push(rowData[group]);
              });

              rows.push(row); // Add the row to the output
            });
          } else {
            // Handle the case where a specific year is selected (including "Older" for <=2021)
            if (selectedYear !== "All Years") {
              const headers = ["Month", selectedYear.toString()];
              rows.push(headers); // Add headers to rows

              // Build data rows for each month
              Object.keys(data).forEach((month) => {
                const cleanedMonth = month.replace(/\s?\d{4}/, "").trim(); // Remove the year (e.g., "2024") from the month
                const row = [cleanedMonth]; // Add the cleaned month as the first column
                const monthData = data[month];

                // Initialize year group data
                const rowData = { [selectedYear]: 0 };

                // Map the data for the corresponding year
                monthData.forEach((item) => {
                  const yearGroup = item.year_group.toString(); // Ensure year_group is compared as string
                  const value = item.value || 0; // Default to 0 if value is undefined

                  // Map "Older" data to <=2021 (or selectedYear)
                  if (yearGroup === "Older" && selectedYear === `<=${currentYear - 3}`) {
                    rowData[selectedYear] = value; // Set value from "Older" group
                  } else if (yearGroup === selectedYear.toString()) {
                    rowData[selectedYear] = value;
                  }
                });

                // Push the row with data for the selected year
                rows.push([cleanedMonth, rowData[selectedYear]]);
              });
            } else {
              // Handle the "All Years" case (no dealer selected)
              const headers = [
                "Month",
                currentYear.toString(), // Current year
                (currentYear - 1).toString(), // Current year - 1
                (currentYear - 2).toString(), // Current year - 2
                `<=${currentYear - 3}`, // Older data (current year - 3 or earlier)
                "Unknown",
              ];

              rows.push(headers); // Add headers to rows

              // Build data rows for each month
              Object.keys(data).forEach((month) => {
                const cleanedMonth = month.replace(/\s?\d{4}/, "").trim(); // Remove the year from the month
                const row = [cleanedMonth]; // Add the cleaned month as the first column
                const monthData = data[month];

                // Initialize year group data
                const rowData = {
                  [currentYear]: 0,
                  [currentYear - 1]: 0,
                  [currentYear - 2]: 0,
                  [`<=${currentYear - 3}`]: 0,
                  [`Unknown`]: 0,
                };

                // Map the data for the corresponding years
                monthData.forEach((item) => {
                  const yearGroup = item.year_group.toString(); // Ensure year_group is compared as string
                  const value = item.value || 0; // Default to 0 if value is undefined

                  if (yearGroup === currentYear.toString()) rowData[currentYear] = value;
                  if (yearGroup === (currentYear - 1).toString()) rowData[currentYear - 1] = value;
                  if (yearGroup === (currentYear - 2).toString()) rowData[currentYear - 2] = value;
                  if (yearGroup === "Older") rowData[`<=${currentYear - 3}`] = value;
                  if (yearGroup === "Unknown") rowData[`Unknown`] = value;
                });

                // Push the row with data for each year
                rows.push([cleanedMonth, rowData[currentYear], rowData[currentYear - 1], rowData[currentYear - 2], rowData[`<=${currentYear - 3}`], rowData[`Unknown`]]);
              });
            }
          }

          // Create Excel workbook and worksheet
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(rows); // Convert rows to Excel worksheet

          XLSX.utils.book_append_sheet(wb, ws, `${this.registrationType} - Stacked Column`);

          // Export the workbook to an Excel file
          const xlsxOutput = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([xlsxOutput], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          // Create download link and trigger download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${this.registrationType} - Stacked Column Chart.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // console.warn("No data found for Excel export.");
        }
      } catch (error) {
        // console.error("Error downloading stacked chart data:", error);
      }
    },
    async downloadExcelOverallProductionYear() {
      try {
        const selectedYear = this.selectedYearGlobal; // Use global year
        const params = {};

        // Add params only if user selected a year
        if (selectedYear && selectedYear !== "All Years") {
          params.manufacture_year = selectedYear;
        }
        if (this.dateRange.start) {
          params.start = this.dateRange.start;
        }
        if (this.dateRange.end) {
          params.end = this.dateRange.end;
        }

        // Define the URL based on the registration type (New ID / Existing ID)
        const url = this.registrationType === "New ID" ? "/api/distribution/qr-registration/new-id-by-production-year" : "/api/distribution/qr-registration/existing-id-by-production-year";

        // Fetch data from the API
        const res = await axios.get(url, { params });

        if (res.data && res.data.data) {
          const data = res.data.data;
          const rows = [];

          // Build headers (e.g., Year, Value)
          const headers = ["Year", "Value"];
          rows.push(headers); // Add headers to rows

          // Build data rows
          data.forEach((item) => {
            const row = [item.year_group, item.value || 0];
            rows.push(row); // Add row to rows
          });

          // Create Excel workbook and worksheet
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(rows); // Convert rows to Excel worksheet

          XLSX.utils.book_append_sheet(wb, ws, `${this.registrationType === "Existing ID" ? "Ex ID" : this.registrationType} - Overall Production`);

          // Export the workbook to an Excel file
          const xlsxOutput = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([xlsxOutput], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${this.registrationType} - Overall Production Year Chart.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // console.warn("No data found for Excel export.");
        }
      } catch (error) {
        // console.error("Error downloading overall production data:", error);
      }
    },
    async downloadExcelSegment() {
      try {
        // Use a local variable to handle the selected year logic
        let yearToUse = this.selectedYearSegment || this.selectedYearGlobal;

        // If no year is selected, use "All Years" as the default
        if (!yearToUse) {
          yearToUse = "All Years"; // Use a local variable instead of directly assigning to selectedYear
        }

        const params = {};

        // Add params only if the user selected a year
        if (yearToUse && yearToUse !== "All Years") {
          params.manufacture_year = yearToUse;
        }
        if (this.dateRange.start) {
          params.start = this.dateRange.start;
        }
        if (this.dateRange.end) {
          params.end = this.dateRange.end;
        }

        // Handle when segments are selected
        let isSegmentSelected = false;
        if (this.selectedSegment && this.selectedSegment.length > 0) {
          params.segment = this.selectedSegment.join(","); // Use the selected segments
          isSegmentSelected = true;
        }

        // Define the URL based on the registration type (New ID / Existing ID)
        const url = this.registrationType === "New ID" ? "/api/distribution/qr-registration/new-id-by-segment" : "/api/distribution/qr-registration/existing-id-by-segment";

        // Fetch data from the API
        const res = await axios.get(url, { params });

        if (res.data && res.data.data) {
          const data = res.data.data;
          const rows = [];

          // Define headers based on segment selection
          const headers = ["Month"];
          if (isSegmentSelected) {
            // If segments are selected, create a header for each segment
            this.selectedSegment.forEach((segment) => {
              headers.push(`${segment} (${yearToUse})`);
            });
          } else {
            // Default to "All Segment" if no specific segment is selected
            headers.push(`${yearToUse} (All Segment)`);
          }
          rows.push(headers); // Add headers to the rows

          // Iterate over each month and populate the data by grabbing the value directly
          Object.keys(data).forEach((month) => {
            // Clean the month label by removing the year part
            const cleanedMonth = month.replace(/\s*\d{4}/, "").trim(); // Clean up the month label
            const row = [cleanedMonth]; // Start with the cleaned month name

            const monthData = data[month];

            if (isSegmentSelected) {
              // If segments are selected, add the values for each segment
              this.selectedSegment.forEach((segment) => {
                // Grab the value directly for the selected segment, default to 0 if not found
                const segmentData = monthData.find((item) => item.segment === segment);
                row.push(segmentData ? segmentData.value : 0);
              });
            } else {
              // Default case: handle "All Segment" data
              const segmentData = monthData.find((item) => item.segment === "All Segment");
              // Grab the value directly for "All Segment", default to 0 if not found
              row.push(segmentData ? segmentData.value : 0);
            }

            rows.push(row); // Add the row to the output
          });

          // Create Excel workbook and worksheet
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(rows); // Convert rows to Excel worksheet

          XLSX.utils.book_append_sheet(wb, ws, `${this.registrationType === "Existing ID" ? "Ex ID" : this.registrationType} - Segment`);

          // Export the workbook to an Excel file
          const xlsxOutput = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([xlsxOutput], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          // Create download link and trigger download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${this.registrationType} - Segment Line Chart.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // console.warn("No data found for Excel export.");
        }
      } catch (error) {
        // console.error("Error downloading segment chart data:", error);
      }
    },
    async downloadExcelRegion() {
      try {
        const selectedYear = this.selectedYearRegion || this.selectedYearGlobal; // Use section-specific year if available, otherwise global year

        const params = {};
        // Add params only if user selected a year
        if (selectedYear && selectedYear !== "All Years") {
          params.manufacture_year = selectedYear;
        }
        if (this.dateRange.start) {
          params.start = this.dateRange.start;
        }
        if (this.dateRange.end) {
          params.end = this.dateRange.end;
        }
        if (this.selectedRegion) {
          params.region = this.selectedRegion.split(" - ")[0];
        }

        const url = this.registrationType === "New ID" ? "/api/distribution/qr-registration/new-id-by-region" : "/api/distribution/qr-registration/existing-id-by-region";

        const res = await axios.get(url, { params });

        if (res.data && res.data.data) {
          const data = res.data.data;
          const rows = [];

          // Build headers
          let headers = ["Region Name"];
          const firstRegion = Object.values(data)[0];
          if (firstRegion) {
            headers = headers.concat(firstRegion.map((item) => item.bulan));
          }
          rows.push(headers); // Add headers to rows

          // Build data rows
          for (const [region, values] of Object.entries(data)) {
            const row = [region];

            // Assuming the API returns data for the selected year directly
            const monthlyData = values.map((v) => v.value || 0); // Use the value directly from the API response

            rows.push(row.concat(monthlyData)); // Add the region data row to Excel rows
          }

          // Create Excel workbook and worksheet
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(rows); // Convert rows to Excel worksheet

          XLSX.utils.book_append_sheet(wb, ws, `${this.registrationType === "Existing ID" ? "Ex ID" : this.registrationType} - Region`);

          // Export the workbook to an Excel file
          const xlsxOutput = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([xlsxOutput], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${this.registrationType} - Region.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // console.warn("No data found for Excel export.");
        }
      } catch (error) {
        // console.error("Error downloading region data:", error);
      }
    },
    async downloadExcelDealer() {
      try {
        const selectedYear = this.selectedYearDealer || this.selectedYearGlobal;
        const selectedYearString = selectedYear ? selectedYear.toString() : ""; // Ensure it’s a string

        const currentYear = new Date().getFullYear();
        const cutoffYear = currentYear - 3;

        const params = {};
        // Add params only if a specific year is selected and it’s not <=cutoffYear
        if (selectedYearString && !selectedYearString === "All Years" && !selectedYearString.startsWith("<=")) {
          params.manufacture_year = selectedYearString;
        }

        if (this.dateRange.start) params.start = this.dateRange.start;
        if (this.dateRange.end) params.end = this.dateRange.end;
        if (this.selectedDealer) params.dealer = this.selectedDealer.split(" - ")[0];

        const url = this.registrationType === "New ID" ? "/api/distribution/qr-registration/new-id-by-dealer" : "/api/distribution/qr-registration/existing-id-by-dealer";

        // console.log("API URL:", url);
        // console.log("API Params:", params);

        const res = await axios.get(url, { params });

        // console.log("API Response:", res.data);
        // console.log("Year:", selectedYear);

        if (res.data && res.data.data && Object.keys(res.data.data).length > 0) {
          const data = res.data.data;
          const rows = [];

          let headers = ["Dealer Name"];

          // Headers
          if (selectedYearString === "All Years" || selectedYearString === "") {
            headers.push(currentYear.toString(), (currentYear - 1).toString(), (currentYear - 2).toString(), `<=${cutoffYear}`, "Unknown".toString());
          } else {
            headers.push(selectedYearString);
          }
          rows.push(headers);

          // Content
          for (const [dealer, values] of Object.entries(data)) {
            const row = [dealer];

            if (selectedYearString === "All Years" || selectedYearString === "") {
              const yearGroups = {
                [currentYear]: 0,
                [currentYear - 1]: 0,
                [currentYear - 2]: 0,
                [`<=${cutoffYear}`]: 0,
                [`Unknown`]: 0,
              };

              values.forEach((v) => {
                const year = v.year_group === "Older" ? `<=${cutoffYear}` : v.year_group;
                yearGroups[year] = v.value || 0;
              });

              row.push(yearGroups[currentYear], yearGroups[currentYear - 1], yearGroups[currentYear - 2], yearGroups[`<=${cutoffYear}`], yearGroups[`Unknown`]);
            } else {
              const yearKey = selectedYearString.startsWith("<=") ? "Older" : selectedYearString;
              const yearData = values.find((v) => v.year_group === yearKey);

              // console.log(`Dealer: ${dealer}, Year: ${yearKey}, Data: `, yearData);

              row.push(yearData ? yearData.value : 0);
            }

            rows.push(row);
          }

          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(rows);

          XLSX.utils.book_append_sheet(wb, ws, `${this.registrationType === "Existing ID" ? "Ex ID" : this.registrationType} - Dealer`);

          const xlsxOutput = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([xlsxOutput], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${this.registrationType} - Dealer.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // console.warn("No data found for Excel export.");
        }
      } catch (error) {
        // console.error("Error downloading dealer data:", error);
      }
    },
  },
};
</script>

<style>
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border: none;
}

.new-id-production-year {
  min-height: 625px;
}

.card-table {
  min-height: 300px;
}

.card-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.form-select,
.btn {
  font-size: 0.875rem;
}

.bi-download {
  margin-left: 0.25rem;
}

.action-header {
  width: 1%;
  /* This forces the column to be as narrow as possible */
  white-space: nowrap;
  color: #4b4b4b;
  background-color: #ffffff;
  text-align: left;
}

.action-body {
  width: 1%;
  /* This forces the column to be as narrow as possible */
  white-space: nowrap;
  color: #4b4b4b;
  background-color: #ffffff;
  border-top: none;
  border-bottom: none;
  text-align: left;
}

.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
}

.hso-download-button {
  margin-top: 12px;
  height: 40px;
  margin-top: 0px;
  color: #2f2f2f;
  background-color: #ffffff;
  box-shadow: none;
  width: 100%;
  white-space: nowrap;
}

.hso-table-header {
  color: #4b4b4b;
  background-color: #ffffff;
  text-align: left;
}

.hso-table-body {
  border-bottom: none;
  border-top: none;
  text-align: left;
}

.hso-table-pagination {
  color: #cbcbcb;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.tooltip-content {
  font-size: 0.9em;
  color: #666;
}

.filter-date {
  position: relative;
}

.filter-date.icon {
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.filter-date.icon.fa-calendar::before {
  content: "\f133";
  box-sizing: border-box;
  font-family: "Font Awesome 6 Free";
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flatpickr-month select.flatpickr-monthDropdown-months {
  background-color: #3c97fe;
}

.flatpickr-month select.flatpickr-monthDropdown-months option {
  background-color: #fff;
  color: black;
}
</style>
